
import { ethers } from "ethers";

import NFTContract from "@/contracts/jokercharlie.js"

const config = {
    address: "0x8A92fFAAb9dF9405f3832b029828251B821e9028", // "0x86f448F2f5a202c613E8904592Ae49758d1e0Bf8", // , "0xaCb8D0e5863863f63954A50B91E9675aE44C51D5",
    chainId: 4,
    chainName: 'Ethereum Testnet Rinkeby',
    scannerURL: 'https://rinkeby.etherscan.io',
    openseaURL: 'https://testnets.opensea.io',
    contractURL: 'https://rinkeby.etherscan.io/address/0x8A92fFAAb9dF9405f3832b029828251B821e9028',  // Don't forget to update this
    openseaContractURL: 'https://testnets.opensea.io/collection/joker-charlie-genesis-v2'
}

NFTContract.address = config.address

const baseurl = 'https://genesis.jokercharlieclub.com/vd'

const state = {
    data: { 
        name: 'JCC Genesis',
        symbol: 'JCCGENESIS', 
        isPublicMintActive: false,
        isPrivateMintActive: false,
        isVoucherMintActive: false,
        publicMintPrice: 0.555,
        privateMintPrice: 0.555,
        maxPublicMintAmount: 2,    
        maxPrivateMintAmount: 1,
        maxTotalSupply: 0,
        maxPrivateMintSupply: 0,
        totalPublicSold: 0,
        totalPrivateSold: 0,
        totalVoucherClaimed: 0,
        totalVoucherIssued: 0,
        totalProjectMint: 0,
        ///////////////////////////////////////
        contractAddress: config.address,
        totalSupply: 0,
    },    
    vouchers: [],
    voucher: null,    
    whitelist: null,
    saleState: 'idle', // close, public, private, voucher
    nextSaleStart: null,
    currentSaleEnd: null,
    currentSaleEndUTC: null,
    mintPrice: "555000000000000000",
    maxMintAmount: 2,
    minMintAmount: 1,
    contract: null,
    message: "",
    valid: false
};

const getters = {
    getData: (state) => state.data,
    isValid: (state) => state.valid,    
    isPublicMintActive: (state) => state.data.isPublicMintActive,
    isPrivateMintActive: (state) =>state.data.isPrivateMintActive,
    isVoucherMintActive: (state) => state.data.isVoucherMintActive,
    getTotalReserved: (state) =>  {
        let amount = state.data.totalSupply*1
        amount += state.data.totalVoucherIssued*1
        amount -= state.data.totalVoucherClaimed *1               
        return  amount
    },
    getTotalSupply: (state) => state.data.totalSupply,
    maxPublicMintAmount: (state) => state.data.maxPublicMintAmount,
    maxPrivateMintAmount: (state) => state.data.maxPrivateMintAmount,
    contractAddress: (state) => state.contractAddress,
    ////////////////////////////////////////////////////////////
    getSaleState: (state) => state.saleState, 
    getMintPrice: (state) => state.mintPrice,
    getMinMintAmount: (state) => state.minMintAmount,
    getMaxMintAmount: (state) => state.maxMintAmount,
    getWhitelist: (state) => state.whitelist,    
    getVoucher: (state) => state.voucher,
    ////////////////////////////////////////////////////////////
    getSupportChainId:   () => config.chainId,    
    getSupportChainName: () => config.chainName,  
    getContract: (state) => state.contract,
    getScannerURL: () => config.scannerURL,
    getContractURL: () => config.contractURL,
    getOpenseaURL: () => config.openseaURL,
    getOpenseaContractURL: () => config.openseaContractURL
};

const actions = {
    async fetchHttpData({commit}) {
        await window.axios.get(`${baseurl}/`).then((response) => {            
            commit("setData", response.data)
        }).catch((error)=>{
            console.log(error)
        })
    },
    async fetchContractData({commit, rootState}) {
        let contract = rootState.nft.contract
        if (contract === null) {
            console.log(`Error: call fetchContractData when contract is null!!`)
            return
        }
        let status = await contract.minterStatus()
        let totalSupply = await contract.totalSupply()
        commit("setDataArray", [...status, totalSupply])
    },
    async fetchVouchersData({commit, rootState}, account) {
        let contract = rootState.nft.contract
        if (contract === null) {
            console.log(`Error: call fetchVouchersData when contract is null!!`)
            return
        }
        // console.log(`fetchVoucherData(${account})`)
        await window.axios.get(`${baseurl}/voucher?address=${account}&contract=${NFTContract.address}`).then(async (response) => {            
            let vouchers = []                        
            let fvoucher = null
            let fwhitelist = null            
            // console.log(response)
            for (var i = 0 ; i < response.data.length; i++) {                              
                let voucher = response.data[i]
                if (voucher.type !== 'W' && voucher.type !== 'V') continue                                            
                // console.log(`checking {voucher.id}`)
                // console.log(voucher)
                let usedAmount = (await contract.getAmountUsed(voucher.id)).toNumber()

                if (voucher.type == 'W' && usedAmount != 0) continue                // white-list can only used once
                if (voucher.type == 'V' && usedAmount >= voucher.amount) continue   // voucher can be used until it is fully used
                // console.log(`UsedAmount=${usedAmount}`)
                voucher.usedAmount = usedAmount
                vouchers.push(voucher)
                // console.log(voucher)
                if (voucher.type === 'V' && fvoucher === null) {
                    fvoucher = voucher                    
                } else if (fwhitelist === null) {
                    fwhitelist = voucher                    
                }
            }
            //////////////////////////////////////////////////////////////            
            commit("setVoucher",{vouchers, whitelist: fwhitelist, voucher: fvoucher})
        })        
    },
    async initContract({commit, rootState}) {                
        if (!rootState.accounts.isConnected) {            
            commit("setContract", null)            
            return;
        }                        
        try {            
            let signer   = rootState.accounts.ethersProvider.getSigner()              
            let contract = new ethers.Contract(NFTContract.address, NFTContract.abi, signer)                                        
            commit("setContract", contract)
        } catch {
            commit("setMessage" , "Network is not support")
            commit("setContract", null)                
        }    
    },

};

const mutations = {
    setContract(state,contract) {
        state.contract = contract
    },
    setMessage(state,message) {
        state.message = message
    },
    setData(state, val) {
        state.data.isPublicMintActive   = val.isPublicMintActive
        state.data.isPrivateMintActive  = val.isPrivateMintActive
        state.data.isVoucherMintActive  = val.isVoucherMintActive
        state.data.publicMintPrice      = val.publicMintPrice
        state.data.privateMintPrice     = val.privateMintPrice
        state.data.maxPublicMintAmount  = val.maxPublicMintAmount
        state.data.maxPrivateMintAmount = val.maxPrivateMintAmount
        state.data.maxTotalSupply       = val.maxTotalSupply
        state.data.maxPrivateMintSupply = val.maxPrivateMintSupply
        state.data.totalPublicSold      = val.totalPublicSold
        state.data.totalPrivateSold     = val.totalPrivateSold
        state.data.totalVoucherClaimed  = val.totalVoucherClaimed
        state.data.totalVoucherIssued   = val.totalVoucherIssued        
        state.data.totalProjectMint     = val.totalProjectMint
        state.data.totalSupply          = val.totalSupply
        state.valid = true
        //////////////////////////////////////////////////////////////
        state.mintPrice  = val.publicMintPrice
        if (state.data.isPublicMintActive) {
            state.maxMintAmount = val.maxPublicMintAmount            
        } else if (state.data.isPrivateMintActive) {
            state.maxMintAmount = val.maxPrivateMintAmount
        }
        console.log(state.data)
    },
    setVoucher(state, val) {
        state.vouchers = val.vouchers
        state.voucher  = val.voucher
        state.whitelist = val.whitelist 
        
        if (state.voucher === undefined) state.voucher = null
        if (state.whitelist == undefined) state.whitelist = null
        // infer sale state
        if (state.data.isVoucherMintActive && state.voucher !== null) {
            state.maxMintAmount = state.voucher.amount - state.voucher.usedAmount
            state.minMintAmount = state.voucher.amount - state.voucher.usedAmount
            state.mintPrice = state.voucher.price
            state.saleState = 'voucher'
        } else if (state.data.isPrivateMintActive && state.whitelist !== null) {
            state.minMintAmount = 1
            state.maxMintAmount = (state.whitelist.amount === 0) ? state.data.maxPrivateMintAmount : state.whitelist.amount
            state.mintPrice = state.data.privateMintPrice
            state.saleState = 'private'        
        } else if (state.data.isPublicMintActive) {
            state.minMintAmount = 1
            state.maxMintAmount = state.data.maxPublicMintAmount            
            state.mintPrice = state.data.publicMintPrice
            state.saleState = 'public'
        } else {
            state.mintPrice = state.data.publicMintPrice
            if (state.data.isPrivateMintActive || state.data.isVoucherMintActive) {
                state.saleState = 'noteligible'
            } else {
                state.saleState = 'idle'
            }
        }
        console.log(`saleState(${state.saleState})`)
        // console.log(state.whitelist)
        // console.log(state.voucher)
        // console.log(state.mintPrice)
    },
    setDataArray(state, val) {        
        if (val.length >=  1) state.data.isPublicMintActive = (val[0] == 1) ? true : false
        if (val.length >=  2) state.data.isPrivateMintActive = (val[1] == 1) ? true : false
        if (val.length >=  3) state.data.isVoucherMintActive = (val[2] == 1) ? true : false
        if (val.length >=  4) state.data.publicMintPrice = val[3]
        if (val.length >=  5) state.data.privateMintPrice = val[4]
        if (val.length >=  6) state.data.maxPublicMintAmount = val[5]
        if (val.length >=  7) state.data.maxPrivateMintAmount = val[6]
        if (val.length >=  8) state.data.maxTotalSupply = val[7]
        if (val.length >=  9) state.data.maxPrivateMintSupply = val[8]
        if (val.length >= 10) state.data.totalPublicSold = val[9]
        if (val.length >= 11) state.data.totalPrivateSold = val[10]
        if (val.length >= 12) state.data.totalVoucherClaimed = val[11]
        if (val.length >= 13) state.data.totalVoucherIssued = val[12]
        if (val.length >= 14) state.data.totalSupply = val[13]
        state.valid = true
        // infer sale state
        if (state.data.isVoucherMintActive && state.voucher !== null) {
            state.maxMintAmount = state.voucher.amount - state.voucher.usedAmount
            state.minMintAmount = state.voucher.amount - state.voucher.usedAmount
            state.mintPrice = state.voucher.price
            state.saleState = 'voucher'
        } else if (state.data.isPrivateMintActive && state.whitelist !== null) {
            state.minMintAmount = 1
            state.maxMintAmount = (state.whitelist.amount === 0) ? state.data.maxPrivateMintAmount : state.whitelist.amount
            state.mintPrice = state.data.privateMintPrice
            state.saleState = 'private'        
        } else if (state.data.isPublicMintActive) {
            state.minMintAmount = 1
            state.maxMintAmount = state.data.maxPublicMintAmount            
            state.mintPrice = state.data.publicMintPrice
            state.saleState = 'public'
        } else {
            if (state.data.isPrivateMintActive || state.data.isVoucherMintActive) {
                state.saleState = 'noteligible'
            } else {
                state.saleState = 'idle'
            }
        }
    }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
